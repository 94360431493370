/* eslint-disable complexity */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
// External lib
import Bugsnag from '@bugsnag/js';
// Api url
import { APIURL, LoaderServ } from './index';
// Constant
import { API_NAMES, API_SOURCE, IS_DEV } from '../Constants';
import { Router } from '@angular/router';
// Interface
interface Res { response: any; }

@Injectable({
	providedIn: 'root'
})
export class ApiServ {

	private loaderId: string = '';

	constructor(private router: Router, private http: HttpClient, private APIURL: APIURL, private loader: LoaderServ) { }
	/**
	 * Set loader id for api handle error case hide the loader.
	 * @param id: loader id
	 */
	public setLoaderId(id: string = 'main') {
		this.loaderId = id;
	}
	/**
	 * Call simple api without path params and query params
	 * @param method GET, POST, PUT or DELETE
	 * @param path Slug of API path
	 * @param body Data to pass in API, only for post and put methods
	 * @returns
	 */
	public callApi(method: string, path: string, body?: any, header?: any, isCustom?: boolean): any {
		let apiUrl = this.getApiUrl(path); // Generate API URL
		return this.executeApi(method, apiUrl, body, null, header, isCustom); // execute api
	}
	/**
	 * Call api with query params
	 * @param method GET, POST, PUT or DELETE
	 * @param path Slug of API path
	 * @param queryParams Object of query parameters, for example we have requirement to pass following (name=user & age=24) query parameters in API, then this object will come something like this { name: 'user', age: 24 }
	 * @param body Data to pass in API, only for post and put methods
	 * @returns API Response
	 */
	public callApiWithQueryParams(method: string, path: string, queryParams?: any, body?: any, header?: any): any {
		let apiUrl = this.getApiUrl(path); // Generate API URL
		let apiParams = this.getApiParams(queryParams); // Generate query parameter instance
		return this.executeApi(method, apiUrl, body, apiParams, header); // execute api
	}
	/**
	 * Call api with path variables
	 * @param method GET, POST, PUT or DELETE
	 * @param path Slug of API path
	 * @param pathVariables Array of parameters which need to passed in API URL, for example there is an API with URL /user/userId/address/addressId. In this API userId & addressId is dynamic, so you have to pass array like this [userId, addressId]
	 * @param body Data to pass in API, only for post and put methods
	 * @returns API Response
	 */
	public callApiWithPathVariables(method: string, path: string, pathVariables: any[] = [], body?: any, header?: any): any {
		let apiUrl = this.getApiUrl(path, pathVariables); // Generate API URL
		return this.executeApi(method, apiUrl, body, null, header); // execute api
	}
	/**
	 * Call api with path variables and query params
	 * @param method GET, POST, PUT or DELETE
	 * @param path Slug of API path
	 * @param pathVariables Array of parameters which need to passed in API URL, for example there is an API with URL /user/userId/address/addressId. In this API userId & addressId is dynamic, so you have to pass array like this [userId, addressId]
	 * @param queryParams Object of query parameters, for example we have requirement to pass following (name=user & age=24) query parameters in API, then this object will come something like this { name: 'user', age: 24 }
	 * @param body Data to pass in API, only for post and put methods
	 * @returns API Response
	 */
	public callApiWithPathQueryVars(method: string, path: string, pathVariables: any[] = [], queryParams?: any, body?: any, header?: any): any {
		let apiUrl = this.getApiUrl(path, pathVariables); // Generate API URL
		let apiParams = this.getApiParams(queryParams); // Generate query parameter instance
		return this.executeApi(method, apiUrl, body, apiParams, header); // execute api
	}
	/**
	 * execute the api
	 * @param method GET, POST, PUT or DELETE
	 * @param url api url
	 * @param body Data to pass in API, only for post and put methods
	 * @param queryParams Object of query parameters, for example we have requirement to pass following (name=user & age=24) query parameters in API, then this object will come something like this { name: 'user', age: 24 }
	 * @returns API Response
	 */
	private executeApi(method: string, url: string, body?: any, queryParams?: any, header?: any, isCustom?: boolean): any {
		// this.loader.show(this.loaderId);
		// pass data to pass in API
		let apiBody: any = body && JSON.stringify(body);
		if (method) {
			switch (method) {
				case 'GET':
					return this.get(url, queryParams, header, isCustom);
				case 'POST':
					return this.post(url, apiBody, queryParams, header);
				case 'PUT':
					return this.put(url, apiBody, queryParams, header);
				case 'DELETE':
					return this.delete(url, queryParams, header);
			}
		}
		return this.get(url, queryParams);
	}
	/**
	 * Generate API URL
	 * @param path Slug of API URL
	 * @param pathVariables Array of parameters which need to passed in API URL
	 * @returns API URL in string format
	 */
	public getApiUrl(path: string, pathVariables?: any): string {
		let apiUrl = API_NAMES[path];
		// If API URL contains some dynamic parameters then generate API URL according to those parameters
		if (pathVariables && pathVariables.length > 0) {
			let i = 1;
			for (let param of pathVariables) {
				let replace = 'P' + i;
				apiUrl = this.setCharAt(apiUrl, replace, param);
				i++;
			}
		}
		// Get the api source like api/v1, theme/v1 etc
		return this.getApiSource(path) + apiUrl;
	}
	/**
	 * Replace specific character/word with some other character/word
	 * @param str String on which we want to replace some character/word
	 * @param find The character/word which we want to replace
	 * @param replace The character/word which we want to add in string
	 * @returns Returns updated string
	 */
	private setCharAt(str: string, find: string, replace: any): string {
		const escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
		return str.replace(new RegExp(escapedFind, 'g'), replace);
	}
	/**
	 * Generate instance of httpParams
	 * @param queryParams Object of query parameters
	 * @returns Instance of httpParams
	 */
	private getApiParams(queryParams: any): any {
		let httpParams = new HttpParams();
		if (queryParams) {
			let keys = Object.keys(queryParams);
			if (keys && keys.length > 0) {
				for (let opt in queryParams) {
					httpParams = httpParams.append(opt, queryParams[opt]);
				}
			}
		}
		return { params: httpParams };
	}
	/**
	 * Hit API with get method
	 * @param path API URL which we want to hit
	 * @param queryParams Query parameters to passed in API
	 * @returns API response
	 */
	private get(path: string, queryParams?: any, header?:any, isCustom:boolean = false): Observable<any> {
		let options = {
			headers: header && new HttpHeaders(header),
			params: queryParams && queryParams.params
		}
		if (!isCustom) {
			return this.http.get<Res>(path, options).pipe(map((res: any) => res?.response), catchError((err) => this.handleError(err)));
		} else {
			return this.http.get<Res>(path, options).pipe(map((res: any) => res), catchError((err) => this.handleError(err)));
		}
	}
	/**
	 * Hit API with post method
	 * @param path API URL which we want to hit
	 * @param body Data which we want to post on API
	 * @param queryParams Query parameters to passed in API
	 * @returns API response
	 */
	private post(path: string, body: any, queryParams?: any, header?: any): Observable<any> {
		let options = {
			headers: header && new HttpHeaders(header),
			params: queryParams && queryParams.params
		}
		return this.http.post<Res>(path, body, options).pipe(map((res: any) => res?.response), catchError((err) => this.handleError(err)));
	}
	/**
	 * Hit API with put method
	 * @param path API URL which we want to hit
	 * @param body Data which we want to post on API
	 * @param q// Angular Module queryParams Query parameters to passed in API
	 * @returns API response
	 */
	private put(path: string, body: any, queryParams?: any, header?: any): Observable<any> {
		let options = {
			headers: header && new HttpHeaders(header),
			params: queryParams && queryParams.params
		}
		return this.http.put<Res>(path, body, options).pipe(map((res: any) => res?.response), catchError((err) => this.handleError(err)));
	}
	/**
	 * Hit API with delete method
	 * @param path API URL which we want to hit
	 * @param queryParams Query parameters to passed in API
	 * @returns API response
	 */
	private delete(path: string, queryParams?: any, header?: any): Observable<any> {
		let options = {
			headers: header && new HttpHeaders(header),
			params: queryParams && queryParams.params
		}
		return this.http.delete<Res>(path, options).pipe(map((res: any) => res?.response), catchError((err) => this.handleError(err)));
	}
	/**
	 * Handel error
	 * @param error Error returns from API
	 * @returns Error message
	 */
	private handleError(error: HttpErrorResponse): any {
		// Hide loader
		this.loader.stopAll();
		this.loader.hide(this.loaderId);
		let errorObj = {
			request_url: error.url, 			// URL of the failed request
			status: error?.status, 				// HTTP status code
			status_text: error?.statusText,  // Status text
			message: error?.message, 			// Message
			error_details: error?.error, 		// Body of the error response
		}
		if(IS_DEV){
			console.log(JSON.stringify(errorObj));
		}
		// Bugsnag added
		Bugsnag.notify(new Error(JSON.stringify(errorObj)));
		// return throwError(() => errorMessage);
		return of(null);
	}
	/**
	 * Api source
	 * @param path, api path like appload-new etc
	 * @returns api source like: api/v1, theme/v1 etc
	 */
	private getApiSource(path: string): string {
		if (API_SOURCE.Theme.includes(path)) {
			return this.APIURL.themeUrl;
		} else if (API_SOURCE.ThemeV3.includes(path)) {
			return this.APIURL.themeUrlV3;
		} else if (API_SOURCE.Campaign.includes(path)) {
			return this.APIURL.campaignUrl;
		} else if (API_SOURCE.Leads.includes(path)) {
			return this.APIURL.leadsUrl;
		} else if (API_SOURCE.Trans.includes(path)) {
			return this.APIURL.transUrl;
		} else if (API_SOURCE.IpInfo.includes(path)) {
			return this.APIURL.ipInfoUrl;
		} else if (API_SOURCE.ApiV3.includes(path)) {
			return this.APIURL.apiV3;
		} else if (API_SOURCE.Json.includes(path)) {
			return this.APIURL.assetsBase;
		} else if (API_SOURCE.Checklist.includes(path)) {
			return this.APIURL.checklistUrl;
		} else if(API_SOURCE.ApiV5.includes(path)) {
			return this.APIURL.apiV5;
		} else if (API_SOURCE.Invoice.includes(path)) {
			return this.APIURL.invoiceUrl;
		}else if(API_SOURCE.BKsyncUrl.includes(path)){
			return this.APIURL.bksyncUrl;
		}else if(API_SOURCE.LocalJson.includes(path)){
			return this.APIURL.baseUrl;
		}else if(API_SOURCE.BKUtilUrl.includes(path)){
			return this.APIURL.BKUtilUrl;
		}else {
			return this.APIURL.apiUrl;
		}
	}



	/**
	 * check the api res api status.
	 * @param res api response
	 * @returns boolean
	 */
	public checkAPIRes(res: any, navigate: boolean = true): boolean {
		if (res && res.api_status == 1) {
			return true;
		} else if (res && res.api_status == 10) {
			if (navigate) {
				this.router.navigate(['/login']);
				try {
					localStorage.removeItem('currentUser');
					localStorage.removeItem('passwordProtected');
				} catch (err) { /* empty */ }
			}
			return false;
		} else {
			this.loader.hide(this.loaderId);
			return false;
		}
	}
}
